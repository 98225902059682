import dayjs from 'dayjs'

export default function (el) {
  const posts = $.get('/wp-json/tribe/events/v1/events?per_page=3')
  posts.then(({ events }) => {
    const eventsHtml = events.map((event) => {
      const eventDate = dayjs(event.start_date)
      return (`
        <div class="column js-three-block-event" data-offset="450">
            <a href="${event.url}" class="event-page">
              <p class="eventDate">
                <span class="month">${eventDate.format('MMM')}</span>
                <span class="day">${eventDate.format('DD')}</span>
              </p>
              <p class="time">${eventDate.format('hh:mm A')}</p>
              <h3>${event.title}</h3>
            </a>
          </div>
      `)
    })

    $(el).html(eventsHtml)
  })
}