import $ from 'jquery'
import init from 'lib/init'

document.addEventListener('DOMContentLoaded', () => {
  init({
    module: 'modules'
  }).mount()
})


$(function() {
  var mobileMenu = 1024;

  /* Loader */
  $(document).ready(function(){
      setTimeout(function(){ $('.site-header').addClass('animate')}, 600);
      setTimeout(function(){ $('.main-content-wrap').addClass('animate')}, 700);
      if($('body').hasClass('home')) {
        setTimeout(function(){ $('.videoIntro h1').addClass('animate')}, 1200);
        setTimeout(function(){ $('.videoIntro .sectionSubheadline').addClass('animate')}, 1400);
        setTimeout(function(){ $('.videoIntro .video-wrapper').addClass('animate')}, 1600);
      }
  });

  /* Header */ 

  /* Header Search */
  if($( window ).width() > mobileMenu) {
    $('.navigation-search--desktop .search-btn').click(function(){
      if(!$('.navigation-search--desktop .navbar-search').hasClass('open')) {
        $('.navigation-search--desktop .navbar-search').addClass('open');
        $(this).addClass('open');
        $('.navigation-search--desktop .navigation-search').addClass('open');
      } else {
        $('.navigation-search--desktop .navbar-search').removeClass('open');
        $('.navigation-search--desktop .navigation-search').removeClass('open');
        $(this).removeClass('open');
      }
    });
  } else {
    /* Mobile Search */
    $('.search-btn').click(function(){
      if(!$('.navigation-search--mobile').hasClass('open')) {
        $('.navigation-search--mobile').addClass('open');
        $(this).addClass('open');
      } else {
        $('.navigation-search--mobile').removeClass('open');
        $(this).removeClass('open');
      }
    });
  }

  function headerDropdown() {
    if($( window ).width() > mobileMenu) {
      $('.menu-item-has-children').hover(  
         function () {
      $(this).addClass('dropdown-open');
      $(this).children('.subNav').addClass('open');
         },  
         function () {
          $(this).removeClass('dropdown-open');
          $(this).children('.subNav').removeClass('open');
         }
      );
    } else {
      $('.openSubNav-mobile').click(function(){
        if($(this).hasClass('open')) {
          $(this).removeClass('open');
          $(this).parent().removeClass('dropdown-open');
          $(this).siblings('.nav-drop').removeClass('open');
        } else {
          $(this).addClass('open');
          $(this).parent().siblings().children('.openSubNav-mobile').removeClass('open');
          $(this).parent().addClass('dropdown-open');
          $(this).parent().siblings().removeClass('dropdown-open');
          $(this).siblings('.nav-drop').addClass('open');
        }
      });
    }

    /* Keep dropdown open on subpages - only on desktop */
    if($( window ).width() > mobileMenu) {
      $('.main-navigation .nav > .navItem').each(function() {
        if($(this).hasClass('current_page_parent') && $(this).hasClass('menu-item-has-children') || 
          $(this).hasClass('current_page_item') && $(this).hasClass('menu-item-has-children')) {
          $(this).children('.subNav').addClass('dropdown-always-open');
          $('.main-content-wrap').addClass('dropdown-open');
        }
      });
    }
  }
  headerDropdown();

  function  mobileNavigation() {
    $('.header-mobile-menu').click(function() {
      toggleMenu ($(this));
    });

    function toggleMenu (element) {
      if(!element.hasClass('menu-open')) {
        element.addClass('menu-open');
        $('.main-navigation').addClass('menu-open');
        $('body').addClass('menu-open');
        $('.main-navigation--mobile-overlay-bg').addClass('menu-open');
      } else {
        element.removeClass('menu-open');
        $('.main-navigation').removeClass('menu-open');
        $('body').removeClass('menu-open');
        $('.main-navigation--mobile-overlay-bg').removeClass('menu-open');
      }
    }
  }
  mobileNavigation();

  function headerSticky() {
    /* Header Scroll */
    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('header').outerHeight();

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            if($(window).width() >= 780) {
              var st = $(this).scrollTop();
              $('.site-header').addClass('sticky');
              hasScrolled();
              if($('.translateBar').length > 0) {
                $('.translateBar').addClass('sticky')
              }
              if(st <= 5) {
                 $('.site-header').removeClass('sticky');
                 if($('.translateBar').length > 0) {
                    $('.translateBar').removeClass('sticky')
                  }
              }
            } else {
              hasScrolled();
            }
            didScroll = false;
        }
    }, 100);

    function hasScrolled() {
        var st = $(this).scrollTop();
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        if(st <= 10) {
          $('.site-header').removeClass('header-down');
          $('.site-header').removeClass('header-up');
        } else if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('.site-header').removeClass('header-down').addClass('header-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.site-header').removeClass('header-up').addClass('header-down');
            }
        }

        lastScrollTop = st;
    }
  }
  headerSticky();

  /* Scroll to error or confirmation message for gform */
  if($(window).width() <= 780) {
    jQuery(document).bind('gform_confirmation_loaded', function(event, formId){
      if($('.contactForm-form').length > 0) {
        var $target = $('.contactForm-form');
      } else {
        var $target = $('.generalForm');
      }
      $('html, body').stop().animate({
          'scrollTop': $target.offset().top - 120
      }, 900, 'swing', function () {
      });
    });
  }


  if (window.location.href.indexOf("/experience/programs/#") > -1) {
      $('html, body').animate({
        scrollTop: $(".splitContent").offset().top - 200
    }, 2000);
  }
  if (window.location.href.indexOf("/admissions-tuition#age-requirments") > -1) {
      $('html, body').animate({
        scrollTop: $("#age-requirments").offset().top - 280
    }, 2000);
  }
  if (window.location.href.indexOf("/admissions-tuition#tuition") > -1) {
      $('html, body').animate({
        scrollTop: $("#tuition").offset().top + 180
    }, 2000);
  }

  if (window.location.href.indexOf("/experience/extracurricular-activities") > -1) {
    $('#program-select').prop('selectedIndex', 1).trigger('change');
  }

  if($('body').hasClass('page-template-page-admissions')) {
    $('.subNav.dropdown-always-open a[href*="#"]').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - 80
          }, 1000);
          return false;
        }
      }
    });
  }
  function jsMobileSlider() {
    if($('.js-mobile-slider').length > 0 && $(window).width() <= 600) {
      $('.js-mobile-slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      arrows: true,
      });
    }
  }
  function jsTabletSlider() {
    if($('.js-tablet-slider').length > 0 && $(window).width() <= 1025) {
      $('.js-tablet-slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      arrows: true,
      });
    }
  }
  function jsMobileDotsSlider() {
    if($('.js-mobile-dots-slider').length > 0 && $(window).width() <= 600) {
      $('.js-mobile-dots-slider').slick({
      dots: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      arrows: false,
      });
    }
  }
  jsMobileDotsSlider();
  jsMobileSlider();
  jsTabletSlider();
  function splitContentTabs() {
    function makeTabCurrent(element) {
      element.addClass('active');
      element.siblings().removeClass('active');
      var tabNavValue = element.attr('data-tab-link');
      var href = element.attr('data-tab-slug');
      window.location.hash = href;
      $('.splitContent-tab').each(function() {
        if($(this).attr('id') == tabNavValue) {
          $(this).addClass('current');
          $(this).siblings('.splitContent-tab').removeClass('current');
        }
      });
      if($( window ).width() <= mobileMenu) {
        $('.linkListNav-mobile option').each(function(){
          var selectValue;
          var firstSelect = $('.linkListNav-mobile.mobileSubmenu select');
          if (window.location.hash.substring(1) == $(this).attr('data-tab-slug')) {
            selectValue = $(this).html();
            $('.linkListNav-mobile select').val(selectValue);
            firstSelect.prop('selectedIndex', 0);
          }
        });
      }
    }
    if($('.js-split-content').length > 0 && $(window).width() > mobileMenu) {

      if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        $('.link-list li').each(function() {
          if($(this).attr('data-tab-slug') == hash) {
            makeTabCurrent($(this));
          }
        });
        if($('.tabbedContent').length > 0) {
          $('html, body').animate({
              scrollTop: $('.tabbedContent').offset().top - 80
          }, 1000);
        } else if ($('.splitContent').length > 0) {
          $('html, body').animate({
              scrollTop: $('.splitContent').offset().top - 80
          }, 1000);
        }
      } else {
        makeTabCurrent($('.link-list li').first());
      }
      $('.link-list li').click(function(e){
        makeTabCurrent($(this));
      });
    } else if ($('.js-split-content').length > 0 && $( window ).width() <= mobileMenu) {
      if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        if($('.tabbedContent').length > 0) {
          $('html, body').animate({
              scrollTop: $('.tabbedContent').offset().top - 80
          }, 800);
        } else if ($('.splitContent').length > 0) {
          $('html, body').animate({
              scrollTop: $('.splitContent').offset().top - 80
          }, 800);
        }
        $('.linkListNav-mobile select option').each(function() {
          if($(this).attr('data-tab-slug') == hash) {
            makeTabCurrent($(this));
          }
        });
      } else {
        makeTabCurrent($('.linkListNav-mobile select option').first());
      }

      $('.linkListNav-mobile select').on('change', function() {
        var selectValue = $(this).val();
        $('.linkListNav-mobile select option').each(function() {
          if($(this).html() == selectValue) {
            makeTabCurrent($(this));
          }
        });  
      });
    }

    $('.js-next-tab-btn').click(function(){
      var nextTab;
      if($(this).parent().parent('.splitContent-tab').is(':last-child') ) {
        nextTab = $('.splitContent-tab.first-tab');
      } else {
        nextTab = $(this).parent().parent('.splitContent-tab').next();
      }
      nextTab.addClass('current');
      nextTab.siblings().removeClass('current');

      var tabId = nextTab.attr('id');
      $('.link-list li').each(function(){
        if($(this).attr('data-tab-link') == tabId) {
          $(this).addClass('active');
          $(this).siblings().removeClass('active');
        }
      });
      if($( window ).width() <= mobileMenu) {
        var tabId = nextTab.attr('id').toLowerCase();
        $('.linkListNav-mobile option').each(function(){
          var selectValue;
          if(tabId == $(this).attr('data-tab-slug')) {
            $('.linkListNav-mobile select').val($(this).html());
          }
        });
      }

      if($('.tabbedContent').length > 0) {
        $('html, body').animate({
          scrollTop: $('.tabbedContent').offset().top - 200
        }, 'slow');
      } else {
        $('html, body').animate({
          scrollTop: $('.generalContent').offset().top - 20
        }, 'slow');
      }
    });
  }
  splitContentTabs();

 if($('.js-slider').length > 0) {
  $('.js-slider').slick();
}

if($('.videoIframe-wrapper').length > 0) {
  const player = new Plyr($('.videoIframe-wrapper .videoIframe').get(0))
  $('.videoPreview-wrapper').click(function(event){
    if(!$(this).hasClass('hide')) {
      $(this).addClass('hide');
      // var iframeSrc = $('.videoIframe iframe').attr('src');
      // var autoplay = "?autoplay=true";
      // $('.videoIframe iframe').attr('src', iframeSrc + autoplay);
      // var player = new Player($('.videoIframe iframe').get(0), { autoplay: true })
      // player.setMuted(true)
      player.play(event)
    }
  });
}

/* Waypoints */
if($('.js-left-right-waypoint').length > 0) {
  var dataOffset;
  if($('.js-left-right-waypoint').attr('data-offset')) {
    dataOffset = $('.js-left-right-waypoint').attr('data-offset');
  } else {
    dataOffset = 600;
  }
  var leftRightWaypoint = $('.js-left-right-waypoint').waypoint(function(direction) {
    var target = $(this.element);
    var leftItem = target.find('.js-left-item');
    var rightItem = target.find('.js-right-item');
    leftItem.addClass('animate');
    rightItem.addClass('animate');
    this.destroy();
  }, {
      offset: dataOffset
  });
}
if($('.js-up-waypoint').length > 0) {
  var dataOffset;
  if($('.js-up-waypoint').attr('data-offset')) {
    dataOffset = $('.js-up-waypoint').attr('data-offset');
  } else {
    dataOffset = 600;
  }
  var singleSliderWaypoint = $('.js-up-waypoint').waypoint(function(direction) {
    var target = $(this.element);
    target.find('.js-up-item').addClass('animate');
    this.destroy();
  }, {
      offset: dataOffset
  });
}
if($('.js-three-block-waypoint').length > 0) {
  var dataOffset;
  if($('.js-three-block-waypoint').attr('data-offset')) {
    dataOffset = $('.js-three-block-waypoint').attr('data-offset');
  } else {
    dataOffset = 600;
  }
  var singleSliderWaypoint = $('.js-three-block-waypoint').waypoint(function(direction) {
    var target = $(this.element);
    setTimeout(function() {
      target.find('.js-three-block-item:nth-child(1)').addClass('animate'); 
    }, 500);
    setTimeout(function() {
      target.find('.js-three-block-item:nth-child(2)').addClass('animate'); 
    }, 800);
    setTimeout(function() {
      target.find('.js-three-block-item:nth-child(3)').addClass('animate'); 
    }, 1000);
    this.destroy();
  }, {
      offset: dataOffset
  });
}
if($('.js-banner-experience-waypoint').length > 0) {
  var dataOffset;
    if($('.js-banner-experience-waypoint').attr('data-offset')) {
    dataOffset = $('.js-banner-experience-waypoint').attr('data-offset');
  } else {
    dataOffset = 600;
  }
  var singleSliderWaypoint = $('.js-banner-experience-waypoint').waypoint(function(direction) {
    var target = $(this.element);
    target.addClass('animate');
    if(direction == "down") {
      $('.translateBar-content p').css('color', 'white');
      $('.translateBar-content a').css('color', 'white');
      $('.translateBar-content p.linkOne').css('border-color', 'white');
    }
    if(direction == "up") {
      $('.translateBar-content p').css('color', '#47983d');
      $('.translateBar-content a').css('color', '#47983d');
      $('.translateBar-content p.linkOne').css('border-color', '#47983d');
    }
  }, {
      offset: dataOffset
  });
}
if($('.js-list-waypoint').length > 0) {
  var dataOffset;
    if($('.js-list-waypoint').attr('data-offset')) {
    dataOffset = $('.js-list-waypoint').attr('data-offset');
  } else {
    dataOffset = 600;
  }
  var singleSliderWaypoint = $('.js-list-waypoint').waypoint(function(direction) {
    var target = $(this.element);
    var delay_time = 0;
    target.find('.js-list-item').each(function() {
        $(this).delay(delay_time).animate({"opacity" : "1"}, "fast");
        delay_time += 120;
    });
    this.destroy();
  }, {
      offset: dataOffset
  });
}

/* Faculty Search */
function FacultySearch(container, items) {
this.containerClass = container;
this.itemsClass = items;
$(this.itemsClass).each(function(k,v) { 
  $(v).data('search', $(v).find('.js-title').text().toLowerCase());
  $(v).addClass('js-searchable');
});

$(this.containerClass).after('<div class="content grid grid--3 facultyResults"></div>');
}


FacultySearch.prototype.inflection_ = function(results, str) {
return (results === 0 || results > 1) ? str + 's' : str;
};

FacultySearch.prototype.search = function(str) {
var self = this,
    results = [];

if (str) {
  $(self.containerClass + ' .js-searchable').each(function(k,v) {
    if ($(v).data('search').indexOf(str.toLowerCase()) !== -1) {
      results.push($(v).clone());
    }
  });
}

var resultsLanguage = [results.length, self.inflection_(results.length, 'Result')].join(' ');

if (results.length === 0) { 
  $(self.containerClass).show();
  $('.facultyResults').hide();
  return resultsLanguage;
}

$(self.containerClass).hide();
$('.facultyResults').empty().append(results).show();

return resultsLanguage;

};

if($('body').hasClass('page-template-page-faculty')) {
var faculty = new FacultySearch('.facultyStaff-feed', '.facultyStaff-item');
$('#facultySearch .form-group input').on('keyup', function () {
  $('.facultySearch-resultsText p').html(faculty.search($('#facultySearch .form-group input').val()));
  if(faculty.search($('#facultySearch .form-group input').val()) == "0 Results" && $('#facultySearch .form-group input').val().length > 0) {
    $('.facultyStaff-no-results').css('display','block');
    $('.facultyStaff-feed').css('display', 'none');
    $('.facultySearch-resultsText').css('display', 'none');
  } else {
    $('.facultyStaff-no-results').css('display','none');
    $('.facultySearch-resultsText').css('display','block');
    if($( window ).width() <= mobileMenu) { 
      $('.linkListNav-mobile').css('display', 'none');
    }
  }

  if($('#facultySearch .form-group input').val().length == 0) {
    $('.facultySearch-resultsText').css('display', 'none');
    if($( window ).width() <= mobileMenu) { 
      $('.linkListNav-mobile').css('display', 'block');
    }
  }

});

$('#facultySearch form').on('submit', function (e) {
  e.preventDefault();
});

$('#facultySearch .form-group input').focus(function(){
  var $target = $('.facultyStaff-search');
    $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 150
    }, 900, 'swing', function () {
    });
});
}

if($('.ginput_container_select').length > 0) {
  $('.ginput_container_select').each(function(){
    $(this).append('<div class="selectArrow"></div>');
  });
  jQuery(document).bind('gform_post_render', function(){
     $('.ginput_container_select').each(function(){
      $(this).append('<div class="selectArrow"></div>');
    });
  });
}

//$('a[href^="http://"]').not('a[href*=gusdecool]').attr('target','_blank');
$('.btn.backToTop-btn').click(function(){
  var $target = $('body');
  $('html, body').stop().animate({
      'scrollTop': $target.offset().top - 120
  }, 900, 'swing', function () {
  });
});

if($('body').hasClass('tax-department')) {
  var $target = $('.facultyStaff-search');
  $('html, body').stop().animate({
      'scrollTop': $target.offset().top 
  }, 900, 'swing', function () {
  });
}

function fbButtonTracker (val) {
  window._fbq.push(['track', 'MyPixelID', { 'value':val }]);
}

  if ($('.mtsnb').length) {
    function offsetHeader () {
      $('.site-header').css({ top: $('.mtsnb').height() })
    }
    $(window).on('resize', offsetHeader)
    offsetHeader()
  }


});

const videoContainers = Array.from(document.querySelectorAll('.lightbox-container'));
document.addEventListener('click', function(e){
  if (e.target.matches('a[data-video]')) {
    e.preventDefault();
    document.body.classList.add('no--scroll');
    const videoID = e.target.dataset.video;
    for (let video of videoContainers) {
      const id = video.dataset.videoId;
      if (videoID == id) {
        video.classList.toggle('open');
      }
    }
  } else {
    document.body.classList.remove('no--scroll');
    for (let video of videoContainers) {
      video.classList.remove('open');
    }
  }
});
